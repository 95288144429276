<template>
  <div class="wrapperSpinner">
    <div class="spinner">
    <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <use xlink:href="#loading0_fill" transform="translate(9.8 11.7)" fill="#E43852"/>
    <use xlink:href="#loading1_fill" transform="translate(.6 9.2)" fill="#E43852"/>
    <use xlink:href="#loading2_fill" transform="translate(7.9)" fill="#E43852"/>
    <use xlink:href="#loading3_fill" transform="translate(12.2 7.3)" fill="#E43852"/>
    <defs><path id="loading0_fill" d="M4.95 9.772c.104-.104 0-.312-.205-.623C1.859 5.614.622 2.495 1.138 0 .004 1.767-.305 3.639.313 5.614c.618 1.975 1.958 3.43 4.02 4.158.308.208.515.208.618 0z"/>
    <path id="loading1_fill" d="M9.765 1.148C8.013.004 6.158-.308 4.2.316 2.24.94.799 2.29.077 4.37c-.103.312-.103.52 0 .624.103.104.31 0 .619-.208C4.2 1.876 7.29.732 9.765 1.148z"/>
    <path id="loading2_fill" d="M.659.078C.349-.026.143-.026.04.078-.063.182.04.39.247.702 3.132 4.236 4.369 7.355 3.854 9.85c1.133-1.767 1.442-3.638.824-5.614C3.957 2.261 2.617.806.658.078z"/>
    <path id="loading3_fill" d="M9.687.665c.103-.312.103-.52 0-.624-.103-.104-.309 0-.618.208C5.565 3.159 2.473 4.407 0 3.887c1.752 1.144 3.607 1.456 5.565.832 1.958-.728 3.298-2.08 4.122-4.054z"/></defs>
    </svg>
    </div>
  </div>
</template>

<script>

export default {
  beforeCreate: function() {
    $("html").addClass("loading");
  },

  destroyed: function() {
    $("html").removeClass("loading");
    $("#content").css("opacity", "1");
  }
};
</script>

<style lang="stylus" scoped>
.wrapperSpinner {
  height: 100%;
  width: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(255, 255, 255, 1);
  justify-content: center;
  align-items: center;

  .spinner {
    animation: spin 1600ms ease-in-out infinite;
    box-shadow: none;
    height: 110px;
    width: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    transform: scale(5);
  }
}

@keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
    transform-origin: center;
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform-origin: center;
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    transform-origin: center;
  }
}
</style>